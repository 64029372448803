import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import { GetAmm2LeadResult, PostAmm2Lead } from '../types'
import { ProjectPathParam } from '../types/common'

const c = initContract()

export const amm2Contract = c.router({
  postAmm2Lead: {
    method: 'POST',
    path: `amm2/lead/:projectId`,
    responses: {
      204: z.undefined().optional(), // tsRest fix for empty response
    },
    pathParams: ProjectPathParam,
    body: PostAmm2Lead,
    summary: 'Create amm2 lead',
  },

  getAmm2Lead: {
    method: 'GET',
    path: `amm2/lead/:projectId`,
    responses: {
      200: GetAmm2LeadResult,
      204: z.undefined().optional(), // tsRest fix for empty response
    },
    pathParams: ProjectPathParam,
    summary: 'Get amm2 lead',
  },
})

export type Amm2ContractType = typeof amm2Contract
