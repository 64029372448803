import { initContract } from '@ts-rest/core'
import { type Amm2ContractType, amm2Contract } from './amm2'
import { type ProjectsContractType, projectsContract } from './projects'
import {
  type BusinessIntelligenceContractType,
  businessIntelligenceContract,
} from './business-intelligence'
import {
  type TokenDesignerContractType,
  tokenDesignerContract,
} from './token-designer'
import {
  type TokenDesignerValuationContractType,
  tokenDesignerValuationContract,
} from './token-designer.valuation'
import {
  type TokenDesignerCalculatorContractType,
  tokenDesignerCalculatorContract,
} from './token-designer.calculator'
import {
  type TokenDesignerPublicPageContractType,
  tokenDesignerPublicPage,
} from './token-designer.public'
import {
  type MarketMakerContractType,
  marketMakerContract,
} from './market-maker'
import { type UsersContractType, usersContract } from './users'
import { type CoinGeckoContractType, coinGeckoContract } from './coin-gecko'
import { activitiesContract, ActivitiesContractType } from './activities'
import { type MembershipContractType, membershipContract } from './membership'
import { onboardingContract, type OnboardingType } from './onboarding'
import {
  tokenUnlockContract,
  type TokenUnlockContractType,
} from './token-unlock'
import {
  marketMakerRFQContract,
  type MarketMakerRFQContractType,
} from './market-maker-rfq'
import {
  exchangeListingContract,
  type ExchangeListingContractType,
} from './exchange-listing'
import { tracingContract, type TracingContractType } from './tracing'

const c = initContract()

type ContractType = {
  activities: ActivitiesContractType
  users: UsersContractType
  projects: ProjectsContractType
  businessIntelligence: BusinessIntelligenceContractType
  amm2: Amm2ContractType
  tokenDesigner: TokenDesignerContractType
  tokenDesignerValuation: TokenDesignerValuationContractType
  tokenDesignerCalculator: TokenDesignerCalculatorContractType
  tokenDesignerPublicPage: TokenDesignerPublicPageContractType
  membership: MembershipContractType
  onboarding: OnboardingType
  coinGecko: CoinGeckoContractType
  marketMaker: MarketMakerContractType
  marketMakerRFQ: MarketMakerRFQContractType
  tokenUnlock: TokenUnlockContractType
  exchangeListing: ExchangeListingContractType
  tracing: TracingContractType
}

export const contract: ContractType = c.router({
  activities: activitiesContract,
  users: usersContract,
  projects: projectsContract,
  businessIntelligence: businessIntelligenceContract,
  amm2: amm2Contract,
  tokenDesigner: tokenDesignerContract,
  tokenDesignerValuation: tokenDesignerValuationContract,
  tokenDesignerCalculator: tokenDesignerCalculatorContract,
  tokenDesignerPublicPage: tokenDesignerPublicPage,
  membership: membershipContract,
  onboarding: onboardingContract,
  coinGecko: coinGeckoContract,
  marketMaker: marketMakerContract,
  marketMakerRFQ: marketMakerRFQContract,
  tokenUnlock: tokenUnlockContract,
  exchangeListing: exchangeListingContract,
  tracing: tracingContract,
})
