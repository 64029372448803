import { z } from 'zod'
import { MetaSchema } from '../calculator'
import { PriceDataSchema, TokenInfoResult } from '../token-unlock'
import {
  EngagementOptionArray,
  EngagementOptionEnum,
  ExchangeTypeArray,
} from '@forgd/supabase'
import { MemberContactType } from '../membership'

export interface MarketMakerRFQOption {
  value: string
  label: string
  description?: string
  loanRequirements?: string[]
}

export const OptionResult = z.object({
  value: z.string(),
  label: z.string(),
})

export type Investor = z.infer<typeof OptionResult>

export const InvestorsResult = z.array(OptionResult)

export const RFQProjectDetailsResult = z.object({
  ticker: z.string().nullable(),
  tokenListed: z.boolean().nullable().default(false),
  link: z.string().nullable(),
  coingeckoId: z.string().nullable(),
  isTdPublished: z.boolean(),
  hasRFQ: z.boolean(),
})
export type RFQProjectDetails = z.infer<typeof RFQProjectDetailsResult>

export const RFQTokenDesignerDetailsResult = z.object({
  tgeDate: z.string().date().nullable(),
  maxTokenSupply: z.number().nullable(),
  fdvAtTGE: z.number().nullable(),
  tokenPriceAtTGE: z.number().nullable(),
  percentageUnlockedAtTGE: z.number(),
  circulatingSupply: z.number().nullable(),
  marketCapAtTGE: z.number().nullable(),
})

export type RFQTokenDesignerDetails = z.infer<
  typeof RFQTokenDesignerDetailsResult
>

export const RFQTokenMarketDetailsResult = TokenInfoResult.merge(
  z.object({
    price: z.number(),
    priceChange24h: z.number(),
    marketChartPrice24h: z
      .object({
        data: z.array(PriceDataSchema),
        meta: MetaSchema,
      })
      .nullable(),
  }),
)

export type RFQTokenMarketDetails = z.infer<typeof RFQTokenMarketDetailsResult>

export const RFQDetailsResult = z.object({
  project: RFQProjectDetailsResult,
  tokenDesignerDetails: RFQTokenDesignerDetailsResult.optional(),
  tokenMarketDetails: RFQTokenMarketDetailsResult.optional(),
})

export type RFQDetails = z.infer<typeof RFQDetailsResult>

export const ExchangeResult = OptionResult.extend({
  url: z.string().nullable().optional(),
  type: z.enum(ExchangeTypeArray),
})

export type Exchange = z.infer<typeof ExchangeResult>

export const ExchangesResult = z.array(ExchangeResult)

export const MarketMakerResult = z.object({
  name: z.string(),
  description: z.string().nullable(),
  imageUrl: z.string().nullable(),
  engagementOptions: z.array(z.enum(EngagementOptionArray)),
  supportedCEX: ExchangesResult,
  supportedDEX: ExchangesResult,
  services: z.array(z.string()),
})

export type MarketMaker = z.infer<typeof MarketMakerResult>

export const MarketMakersResult = z.array(MarketMakerResult)

export const MarketMakerRFQBody = z.object({
  // step 1
  country: z.string().min(2, 'Country is required'),
  isTokenIssuedInUSTerritory: z.boolean().optional(),
  hasLegalOpinionOnUtilityStatus: z.boolean({
    message: 'Legal opinion is required',
  }),
  externalCapital: z
    .string()
    .min(1, 'The Amount of external capital is required'),
  investors: z.array(z.string().uuid()).optional(),
  // step 2
  motivations: z.array(z.string()).min(1),
  otherCEX: z.array(z.string()),
  otherDEX: z.array(z.string()),
  exchangesCEX: z.array(z.string()),
  exchangesDEX: z.array(z.string()),
  // step 3
  marketMakersQty: z.number().min(1).max(4),
  contactType: z.nativeEnum(MemberContactType),
  contact: z.string().min(1, 'Contact is required'),
  engagementOptions: z.array(z.enum(EngagementOptionArray)).min(1),
  tokenSupplyPercentageToAllocate: z.string().min(1),
  stableCoinBudgetToAllocate: z.string().min(1),
  otherMarketMakers: z.array(z.string()),

  calendarLink: z.string().optional(),
})

export type MarketMakerRFQ = z.infer<typeof MarketMakerRFQBody>

export type MMRFQEmailRequest = {
  projectId: string
  projectName: string
  projectTicker: string
  projectLink: string
  tokenListed: boolean
  tgeDate: string
  coingeckoUrl: string
  country: string
  isUSA: boolean
  isUtility: boolean
  externalCapital: string
  investors: string[]
  exchangesCEX: string[]
  exchangesDEX: string[]
  motivations: string[]
  marketMakersQty: number
  engagementOptions: EngagementOptionEnum[]
  tokenSupplyPercentageToAllocate: string
  stableCoinBudgetToAllocate: string
  otherMarketMakers: string[]
  telegram: string | null
}
