<script setup lang="ts">
const uiButton = {
  base: 'h-[50px]',
  rounded: 'rounded-lg',
  variant: {
    solid: 'shadow-none bg-forgd-primary-900 hover:bg-forgd-primary-900 disabled:bg-forgd-neutral-600/30 disabled:border disabled:border-forgd-neutral-600 disabled:text-forgd-neutral-600',
  },
}
</script>

<template>
  <UButton block :ui="uiButton" type="submit" v-bind="$attrs">
    <slot>Continue</slot>
    <template #trailing>
      <UIcon name="i-heroicons-arrow-right" class="w-4 h-4" />
    </template>
  </UButton>
</template>
