<script lang="ts" setup>
import { defu } from 'defu'

interface Teammate {
  firstName?: string
  lastName?: string
  email?: string
  position?: string
}
const props = defineProps<{
  modelValue: Teammate
  canDelete: boolean
  ownerEmail: string
}>()

const emit = defineEmits<{
  'delete': []
  'update:model-value': [Teammate]
}>()

const _value = ref({
  firstName: '',
  lastName: '',
  email: '',
  position: '',
})
watch(() => props.modelValue, (value) => {
  if (value) {
    _value.value = defu({ ...value }, _value.value)
  }
}, { immediate: true })

function emitUpdateModelValue() {
  emit('update:model-value', _value.value)
}

const isInvitingMyself = computed(() => _value.value.email.toLowerCase() === props.ownerEmail)
</script>

<template>
  <UCard>
    <template #header>
      <div class="flex items-center justify-between w-full">
        <div class="text-base font-semibold text-forgd-primary-900">
          Teammate details
        </div>
        <UButton :disabled="!canDelete" color="gray" variant="outline" icon="i-heroicons-trash" @click="$emit('delete')" />
      </div>
    </template>
    <div class="p-5 space-y-5">
      <div class="grid grid-cols-2 gap-5">
        <TdFormGroup label="First name">
          <UInput
            v-model="_value.firstName"
            placeholder="e.g. John"
            size="xl"
            @update:model-value="emitUpdateModelValue"
          />
        </TdFormGroup>
        <TdFormGroup label="Last name">
          <UInput
            v-model="_value.lastName"
            placeholder="e.g. Smith"
            size="xl"
            @update:model-value="emitUpdateModelValue"
          />
        </TdFormGroup>
      </div>
      <TdFormGroup label="Teammate email" :error="isInvitingMyself">
        <UInput
          v-model="_value.email"
          placeholder="e.g. teammat@email.com"
          size="xl"
          @update:model-value="emitUpdateModelValue"
        />
      </TdFormGroup>
      <TdFormGroup label="Teammate position (optional)">
        <UInput
          v-model="_value.position"
          placeholder="e.g. CEO"
          size="xl"
          @update:model-value="emitUpdateModelValue"
        />
      </TdFormGroup>
      <div v-if="isInvitingMyself" class="text-forgd-red-600 text-sm">
        The email used to register your account cannot be used to invite a teammate. Please use a different email.
      </div>
    </div>
  </UCard>
</template>
