import referenceProjects from './projects.json'
import countries from './countries.json'
import { MarketMakerRFQOption } from '../types/market-maker-rfq'
import { EngagementOptionEnum } from '@forgd/supabase'

export const MMRFQExternalCapitalOptions: MarketMakerRFQOption[] = [
  { value: 'no_capital', label: 'No capital raised to date' },
  { value: '100K-500K', label: '$100,000 - $500,000' },
  { value: '500K-1000K', label: '$500,000 - $1,000,000' },
  { value: '1000K-2500K', label: '$1,000,000 - $2,500,000' },
  { value: '2500K-5000K', label: '$2,500,000 - $5,000,000' },
  { value: '5000K-10000K', label: '$5,000,000 - $10,000,000' },
  { value: 'more_than_10000K', label: 'More than $10,000,000' },
]

export const MMRFQMotivationsOptions: MarketMakerRFQOption[] = [
  {
    value: 'price_performance',
    label: 'Price Performance',
    description: '“I want to achieve the highest possible valuation (FDV).”',
  },
  {
    value: 'user_acquisition',
    label: 'User Acquisition',
    description:
      '“I want to acquire as many users as possible at a product / protocol level.”',
  },
  {
    value: 'treasury_management',
    label: 'Treasury Management',
    description:
      '“I want to liquidate tokens to increase its stablecoin reserves and extend the operational runway.”',
  },
  {
    value: 'liquidity_improvement',
    label: 'Liquidity Improvement',
    description:
      '“I want to ensure robust liquidity to facilitate efficient price discovery for our native token.”',
  },
]

export const MMRFQEngagementOptions: MarketMakerRFQOption[] = [
  {
    value: EngagementOptionEnum.LoanCallOption,
    label: 'Loan + Call Option',
    description:
      'Receives a loan of tokens with the option (but not obligation) to purchase tokens at some future date. Adheres to liquidity KPIs per legal contract.',
    loanRequirements: ['Tokens'],
  },
  {
    value: EngagementOptionEnum.RetainerWorkingCapital,
    label: 'Retainer + Working Capital',
    description:
      'Charges monthly fee to operate as a strategic liquidity provider. Trades using your capital (tokens & stablecoins). May receive a profit share.',
    loanRequirements: ['Tokens', 'Stablecoins'],
  },
]

export const MMRFQTokenSupplyPercentageToAllocateOptions: MarketMakerRFQOption[] =
  [
    { value: '0.10-0.25', label: '0.10% - 0.25%' },
    { value: '0.25-0.50', label: '0.25% - 0.50%' },
    { value: '0.50-1.00', label: '0.50% - 1.00%' },
    { value: '1.00-3.00', label: '1.00% - 3.00%' },
    { value: '3.00-5.00', label: '3.00% - 5.00%' },
    { value: 'more_than_5.00', label: 'More than 5.00%' },
  ]

export const MMRFQStableCoinBudgetToAllocateOptions: MarketMakerRFQOption[] = [
  { value: '50K-100K', label: '$50K - $100K' },
  { value: '100K-150K', label: '$100K - $150K' },
  { value: '150K-250K', label: '$150K - $250K' },
  { value: '250K-500K', label: '$250K - $500K' },
  { value: 'more_than_500K', label: 'More than $500K' },
  { value: 'unknown', label: "I don't know" },
]

export { referenceProjects, countries }
