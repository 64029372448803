import unhead_gHmZao4t6P from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.3_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_3zizzqbwdtnfzm7l6kpgimapf4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Q8UHqbaORf from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.3_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_3zizzqbwdtnfzm7l6kpgimapf4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_0Xg0t7ySbj from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+supabase@1.4.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_3qt7Gt8oRN from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.3_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_3zizzqbwdtnfzm7l6kpgimapf4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_cNny0PIUJd from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.3_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_3zizzqbwdtnfzm7l6kpgimapf4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_XFik6g920d from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.3_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_3zizzqbwdtnfzm7l6kpgimapf4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_RjgEVehzh3 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.3_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_3zizzqbwdtnfzm7l6kpgimapf4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Sik9ddARRR from "/opt/render/project/src/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.21.3_typescript@5.6.2_vue@3.5.11_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/ui/.nuxt/components.plugin.mjs";
import prefetch_client_N3eIKQwrRi from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.3_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_3zizzqbwdtnfzm7l6kpgimapf4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import preview_client_2qDxQSCuVS from "/opt/render/project/src/node_modules/.pnpm/@nuxthq+studio@1.1.2_magicast@0.3.5_rollup@4.21.3_webpack-sources@3.2.3/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.mjs";
import slideovers_0mC0JZ1BGn from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.18.6_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.21.3_t_bzrgzammdf6gg25sqlkbwf4e3i/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_IUtAmBYrIh from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.18.6_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.21.3_t_bzrgzammdf6gg25sqlkbwf4e3i/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_M1z9aLjtR9 from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.18.6_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.21.3_t_bzrgzammdf6gg25sqlkbwf4e3i/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_f31VDMrr4w from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.21.3_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_EaqWX65Fgr from "/opt/render/project/src/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.5_rollup@4.21.3_vite@5.4.8_@types+node@22.7.3_sass-embedded@1.7_5idnszmuvqxfcjzd5wro2qmlyy/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_WGZt2aTuBj from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.21._5bn6zks2btzfalwwomrlmtu4fy/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_ilCAmuuP1C from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.21._5bn6zks2btzfalwwomrlmtu4fy/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_UQcrTNmQaV from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.21._5bn6zks2btzfalwwomrlmtu4fy/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import feature_flags_client_yDiIZxtHJu from "/opt/render/project/src/apps/ui/core/plugins/feature-flags.client.ts";
import lottie_YCAtSIlAiw from "/opt/render/project/src/apps/ui/core/plugins/lottie.ts";
import openapi_ueP8qSReR8 from "/opt/render/project/src/apps/ui/core/plugins/openapi.ts";
import sentry_client_4BqsDhRlQY from "/opt/render/project/src/apps/ui/core/plugins/sentry.client.ts";
export default [
  unhead_gHmZao4t6P,
  router_Q8UHqbaORf,
  supabase_client_0Xg0t7ySbj,
  payload_client_3qt7Gt8oRN,
  navigation_repaint_client_cNny0PIUJd,
  check_outdated_build_client_XFik6g920d,
  chunk_reload_client_RjgEVehzh3,
  plugin_vue3_Sik9ddARRR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_N3eIKQwrRi,
  preview_client_2qDxQSCuVS,
  slideovers_0mC0JZ1BGn,
  modals_IUtAmBYrIh,
  colors_M1z9aLjtR9,
  plugin_client_f31VDMrr4w,
  plugin_EaqWX65Fgr,
  scrollbars_client_WGZt2aTuBj,
  presets_ilCAmuuP1C,
  variables_UQcrTNmQaV,
  feature_flags_client_yDiIZxtHJu,
  lottie_YCAtSIlAiw,
  openapi_ueP8qSReR8,
  sentry_client_4BqsDhRlQY
]