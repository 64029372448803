<script setup lang="ts">
import AccountReadOnlyAlert from '#account/components/common/AccountReadOnlyAlert.vue'
import { z } from 'zod'

interface Teammate {
  firstName: string
  lastName: string
  email: string
  position: string
}

const MEMBERSHIP_MAX_ORGANIZATION_MEMBERS_LIMIT = 20 // todo: read from env
const toast = useAppToast()
const auth = useAuth()
const onboarding = useOnboardingV2()
const client = useClient()

const teammateFormsState = ref<Partial<Teammate>[]>([{}])
const isSending = ref(false)

function removeTeammateForm(index: number) {
  teammateFormsState.value.splice(index, 1)
}

function updateTeammateForm(index: number, val: any) {
  teammateFormsState.value = teammateFormsState.value.map((item, i) => i === index ? toRaw(val) : item)
}

const schema = z.array(z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().toLowerCase().email().refine(value => value !== auth.me?.email, {
    message: 'You cannot invite yourself',
  }),
}))

const canAddTeammate = computed(() => {
  return teammateFormsState.value.length < MEMBERSHIP_MAX_ORGANIZATION_MEMBERS_LIMIT
})

const canSubmit = computed(() => {
  const { success } = schema.safeParse(teammateFormsState.value)

  return success
})

async function onSubmit(skipInvitation = false) {
  isSending.value = true
  const membersToInvite = skipInvitation ? [] : teammateFormsState.value.filter(m => m.firstName && m.lastName && m.email)
  try {
    await client.onboarding.complete({
      body: {
        projectId: onboarding.projectId as string,
        members: membersToInvite as Teammate[],
      },
    })

    if (membersToInvite.length > 0) {
      toast.success({ title: 'Invitations sent successfully' })
    }

    // refresh auth so researchers see the dummy project
    await auth.refresh({ from: 'OnboardingV2Teammates' })
    navigateTo(auth.dashboardPath)
  }
  catch (e) {
    console.error(e)
    toast.error({ title: 'Failed to send invitations', description: e.errors[0].message })
  }
  finally {
    isSending.value = false
  }
}

function onError(error) {
  console.log('error', error)
}
</script>

<template>
  <UForm
    :state="teammateFormsState"
    :schema="schema"
    class="flex flex-col gap-10 w-[500px]"
    @submit="onSubmit(false)"
    @error="onError"
  >
    <div class="flex flex-col flex-grow gap-2">
      <AccountReadOnlyAlert />

      <template v-for="(state, key) in teammateFormsState" :key="key">
        <CardCreateTeammate
          :model-value="state"
          :can-delete="teammateFormsState.length > 1"
          :owner-email="auth.me?.email as string"
          @update:model-value="val => updateTeammateForm(key, val)"
          @delete="removeTeammateForm(key)"
        />
      </template>
      <UiButton
        :disabled="!canAddTeammate"
        icon="i-heroicons-plus"
        variant="outline"
        size="lg"
        trailing
        block
        @click="teammateFormsState.push({})"
      >
        Add Another Teammate
      </UiButton>
    </div>

    <div class="flex flex-col gap-2">
      <OnboardingV2Continue class="w-[500px]" :disabled="!canSubmit || isSending" :loading="isSending">
        Send Invitations & Complete
      </OnboardingV2Continue>

      <UiButton variant="ghost" size="lg" block @click="onSubmit(true)">
        I will do this later
      </UiButton>
    </div>
  </UForm>
</template>
