import { z } from 'zod'
import { MemberInvitationDto } from '../membership'
import {
  AreasOfInterestArray,
  BaseLayerTypeArray,
  SectorTypeArray,
} from '@forgd/supabase'
import { datetimeLocal } from '../../helper'
import { FORGD_SYSTEM_COINGECKO_ID } from '../../constants'

export const TProject = z.object({
  id: z.string().uuid(),
  hasToken: z.boolean(),
  isResearch: z.boolean(),
  tokenListed: z.boolean().nullable(),
  name: z.string(),
  ticker: z.string().nullable(),
  coingeckoId: z.string().nullable(),
  image: z.string().nullable(),
  link: z.string().nullable(),
  organizationId: z.string().uuid().nullable().optional(),
  tgeDate: z.string().date().nullable(),
  areasOfInterest: z.array(z.enum(AreasOfInterestArray)).nullable().optional(),
  sector: z.array(z.enum(SectorTypeArray)).nullable().optional(),
  baseLayer: z.array(z.enum(BaseLayerTypeArray)).nullable().optional(),
  createdAt: datetimeLocal,
  updatedAt: datetimeLocal,
  deletedAt: datetimeLocal.nullable().optional(),
})

export const TUpdateProjectBody = z.object({
  hasToken: z.boolean().optional(),
  tokenListed: z.boolean().optional(),
  name: z.string().trim().min(1).optional(),
  ticker: z
    .string()
    .trim()
    .min(1)
    .transform((val) => val.toUpperCase())
    .optional(),
  coingeckoId: z
    .string()
    .nullable()
    .optional()
    .refine(
      (val) => val?.trim() !== FORGD_SYSTEM_COINGECKO_ID,
      'Coingecko cannot be system token',
    ),
  image: z.string().url().optional().nullable(),
  link: z.string().url().optional(),
  organizationId: z.string().uuid().optional(),
  tgeDate: z.string().date().optional(),
  areasOfInterest: z.array(z.enum(AreasOfInterestArray)).nullable().optional(),
  sector: z.array(z.enum(SectorTypeArray)).nullable().optional(),
  baseLayer: z.array(z.enum(BaseLayerTypeArray)).nullable().optional(),
})

const TProjectCreate = TProject.omit({
  id: true,
  coingeckoId: true,
  createdAt: true,
  updatedAt: true,
  deletedAt: true,
})

export const TProjectCreateBody = TProjectCreate.extend({
  members: z.array(MemberInvitationDto).optional(),
})

export const TProjects = z.object({
  items: z.array(TProject),
})
