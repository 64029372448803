import { initContract } from '@ts-rest/core'
import { SearchCoinsResponseBody } from '../types'
import { z } from 'zod'

const c = initContract()

export const coinGeckoContract = c.router({
  searchCoins: {
    method: 'GET',
    path: `coins/search`,
    query: z.object({
      query: z.string().min(1),
    }),
    responses: {
      200: SearchCoinsResponseBody,
    },
    summary: 'Search for coins.',
  },
})

export type CoinGeckoContractType = typeof coinGeckoContract
