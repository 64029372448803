import { z } from 'zod'
import { TProject } from '../projects'
import { MemberContactType, MemberInvitationDto } from '../membership'
import {
  AreasOfInterestArray,
  BaseLayerTypeEnum,
  LeadSourceTypeEnum,
  SectorTypeEnum,
} from '@forgd/supabase'
import { FORGD_SYSTEM_COINGECKO_ID } from '../../constants'

export const InitProjectBody = z.object({
  organizationId: z.string().uuid(),
  isResearch: z.boolean(),
})
export const InitProjectResponse = TProject

export const UpdateProjectTypeBody = z.object({
  projectId: z.string().uuid(),
  isResearch: z.boolean(),
})

export const UpdateProjectTypeResponse = TProject

export const UpdateProjectTokenListedBody = z.object({
  tokenListed: z.boolean(),
})

export const UpdateAreasOfInterestBody = z.object({
  projectId: z.string().uuid(),
  areasOfInterest: z.array(z.enum(AreasOfInterestArray)),
})

export const OwnerDetailsBody = z.object({
  projectId: z.string().uuid(),
  firstName: z.string(),
  lastName: z.string(),
  organizationName: z.string(),
  position: z.string(),
  contactType: z.nativeEnum(MemberContactType),
  contact: z.string().min(1),
  leadSourceType: z.nativeEnum(LeadSourceTypeEnum),
})

export const CompleteBody = z.object({
  projectId: z.string().uuid(),
  members: z.array(MemberInvitationDto),
})

export const GetProgressResponseBody = z.object({
  projectType: z.boolean(),
  tokenListed: z.boolean(),
  projectDetails: z.boolean(),
  areasOfInterest: z.boolean(),
  ownerDetails: z.boolean(),
  memberInvitations: z.boolean(),
})

export const MemberDetailsBody = OwnerDetailsBody.omit({
  leadSourceType: true,
}).extend({
  email: z
    .string()
    .email()
    .transform((email) => email.toLowerCase()),
})

export const GetMemberInvitesResponseBody = z.object({
  members: z.array(MemberDetailsBody),
  maxSeats: z.number(),
})

export const UpdateListedProjectBody = z.object({
  coingeckoId: z
    .string()
    .nullable()
    .refine(
      (val) => (val && val.trim() === FORGD_SYSTEM_COINGECKO_ID ? false : true),
      { message: 'Coingecko cannot be system token' },
    ),
  image: z
    .string()
    .nullable()
    .refine((val) => val !== null && val.trim() !== '', {
      message: 'Token Profile Image is required',
    }),
  name: z.string().trim().min(1, { message: 'Token name is required.' }),
  ticker: z
    .string()
    .trim()
    .min(1, { message: 'Token ticker is required.' })
    .transform((val) => val.toUpperCase()),
  tgeDate: z.string().min(1, { message: 'TGE Date is required.' }).date(),
  link: z.string().url({ message: 'Token website should be a valid URL.' }),
  sector: z
    .array(z.nativeEnum(SectorTypeEnum))
    .nonempty({ message: 'Project Sector is required.' }),
  baseLayer: z
    .array(z.nativeEnum(BaseLayerTypeEnum))
    .nonempty({ message: 'Base Layer is required.' }),
})

const nullableString = z
  .string()
  .nullable()
  .transform((val) => (!val ? null : val))
  .optional()

export const UpdateNotListedProjectBody = z.object({
  coingeckoId: z
    .string()
    .nullable()
    .optional()
    .refine(
      (val) => val?.trim() !== FORGD_SYSTEM_COINGECKO_ID,
      'Coingecko cannot be system token',
    )
    .transform((val) => (!val ? null : val)),
  image: nullableString,
  name: z.string().trim().min(1, { message: 'Token name is required.' }),
  ticker: z
    .string()
    .nullable()
    .transform((val) => {
      if (!val || val.trim() === '') {
        return null
      }
      return val.toUpperCase()
    }),
  tgeDate: z.string().date().nullable().optional(),
  link: z
    .union([
      z.string().url({ message: 'Token website should be a valid URL.' }),
      z.string().nullable(),
      z.string().length(0),
    ])
    .transform((val) => {
      if (!val || val.trim() === '') {
        return null
      }

      return val
    }),
  sector: z.array(z.nativeEnum(SectorTypeEnum)).min(0).optional(),
  baseLayer: z.array(z.nativeEnum(BaseLayerTypeEnum)).min(0).optional(),
})

export const GetMemberDetailsResponseBody = MemberDetailsBody

export const GetOwnerDetailsResponseBody = OwnerDetailsBody

export const GetAreasOfInterestResponseBody = UpdateAreasOfInterestBody

export const GetProjectDetailsResponseBody = z.union([TProject, z.null()])

export type OwnerDetails = z.infer<typeof OwnerDetailsBody>
export type MemberDetails = z.infer<typeof MemberDetailsBody>
export type OnboardingProgress = z.infer<typeof GetProgressResponseBody>
