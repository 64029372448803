import { initContract } from '@ts-rest/core'
import { z } from 'zod'

const c = initContract()

export const tracingContract = c.router({
  events: {
    method: 'POST',
    path: 'events',
    body: z
      .array(
        z.object({
          projectId: z.string().uuid().optional(),
          eventType: z.string(),
          eventData: z.record(z.string(), z.any()),
        }),
      )
      .min(1)
      .max(10),
    responses: {
      204: z.undefined(),
    },
    summary: 'Report a batch of events into the tracing system (min 1, max 10)',
  },
})

export type TracingContractType = typeof tracingContract
