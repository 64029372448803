export enum Amm2sStatusEnum {
  InReview = "in-review",
  Active = "active",
  Delayed = "delayed",
  Scheduled = "scheduled",
  Deactivated = "deactivated",
  Draft = "draft",
  Rejected = "rejected",
};

export const Amm2sStatusArray = [
  "in-review",
  "active",
  "delayed",
  "scheduled",
  "deactivated",
  "draft",
  "rejected",
] as const;

export enum Amm2sStrategyEnum {
  DeltaNeutral = "delta-neutral",
  Tunnel = "tunnel",
  Migrate = "migrate",
  Arbitrage = "arbitrage",
};

export const Amm2sStrategyArray = [
  "delta-neutral",
  "tunnel",
  "migrate",
  "arbitrage",
] as const;

export enum Amm2sTradingstyleEnum {
  Conservative = "conservative",
  Moderate = "moderate",
  Aggressive = "aggressive",
};

export const Amm2sTradingstyleArray = [
  "conservative",
  "moderate",
  "aggressive",
] as const;

export enum AreasOfInterestEnum {
  TokenomicsAndProtocol = "tokenomics_and_protocol",
  GoToMarketPartnerships = "go_to_market_partnerships",
  LegalCompliance = "legal_compliance",
  FinanceAndFundraising = "finance_and_fundraising",
  LaunchChecklist = "launch_checklist",
  TokenomicsResearch = "tokenomics_research",
  MarketMakingResearch = "market_making_research",
  ExchangeListingResearch = "exchange_listing_research",
  AnalysisAndInsights = "analysis_and_insights",
  StrategicPartnerships = "strategic_partnerships",
  PerformanceOptimization = "performance_optimization",
};

export const AreasOfInterestArray = [
  "tokenomics_and_protocol",
  "go_to_market_partnerships",
  "legal_compliance",
  "finance_and_fundraising",
  "launch_checklist",
  "tokenomics_research",
  "market_making_research",
  "exchange_listing_research",
  "analysis_and_insights",
  "strategic_partnerships",
  "performance_optimization",
] as const;

export enum BaseLayerTypeEnum {
  Bitcoin = "Bitcoin",
  Ethereum = "Ethereum",
  Solana = "Solana",
  Aptos = "Aptos",
  Arbitrum = "Arbitrum",
  Avalanche = "Avalanche",
  Injective = "Injective",
  Optimism = "Optimism",
  Polkadot = "Polkadot",
  RadixEcosystem = "Radix Ecosystem",
  Sei = "Sei",
  Sui = "Sui",
  Tron = "TRON",
  Other = "Other",
};

export const BaseLayerTypeArray = [
  "Bitcoin",
  "Ethereum",
  "Solana",
  "Aptos",
  "Arbitrum",
  "Avalanche",
  "Injective",
  "Optimism",
  "Polkadot",
  "Radix Ecosystem",
  "Sei",
  "Sui",
  "TRON",
  "Other",
] as const;

export enum ContactTypeEnum {
  Email = "email",
  Telegram = "telegram",
};

export const ContactTypeArray = [
  "email",
  "telegram",
] as const;

export enum ExchangeAccountTypeEnum {
  Primary = "primary",
  Secondary = "secondary",
};

export const ExchangeAccountTypeArray = [
  "primary",
  "secondary",
] as const;

export enum ExchangeMcapTierEnum {
  Major = "major",
  Large = "large",
  Mid = "mid",
  Low = "low",
  Micro = "micro",
};

export const ExchangeMcapTierArray = [
  "major",
  "large",
  "mid",
  "low",
  "micro",
] as const;

export enum ExchangeTypeEnum {
  Cex = "CEX",
  Dex = "DEX",
};

export const ExchangeTypeArray = [
  "CEX",
  "DEX",
] as const;

export enum LeadSourceTypeEnum {
  Twitter = "twitter",
  Linkedin = "linkedin",
  Email = "email",
  Referral = "referral",
  TeammateInvite = "teammate_invite",
  Other = "other",
};

export const LeadSourceTypeArray = [
  "twitter",
  "linkedin",
  "email",
  "referral",
  "teammate_invite",
  "other",
] as const;

export enum MemberStatusEnum {
  Active = "active",
  Deleted = "deleted",
  Invited = "invited",
};

export const MemberStatusArray = [
  "active",
  "deleted",
  "invited",
] as const;

export enum SectorTypeEnum {
  DeFi = "DeFi",
  GameFi = "GameFi",
  Social = "Social",
  DePin = "DePIN",
  AiAndData = "AI & Data",
  BaseLayer = "Base Layer",
  Memecoin = "Memecoin",
  Wallet = "Wallet",
  Gambling = "Gambling",
  Oracle = "Oracle",
  ECommerce = "eCommerce",
};

export const SectorTypeArray = [
  "DeFi",
  "GameFi",
  "Social",
  "DePIN",
  "AI & Data",
  "Base Layer",
  "Memecoin",
  "Wallet",
  "Gambling",
  "Oracle",
  "eCommerce",
] as const;

export enum TypeOfRaiseEnum {
  TokenSale = "token-sale",
  Equity = "equity",
  Debt = "debt",
  Combination = "combination",
  Other = "other",
  Uncertain = "uncertain",
};

export const TypeOfRaiseArray = [
  "token-sale",
  "equity",
  "debt",
  "combination",
  "other",
  "uncertain",
] as const;

export enum ActivityExecutionTypeEnum {
  Continuous = "continuous",
  AdHoc = "ad_hoc",
};

export const ActivityExecutionTypeArray = [
  "continuous",
  "ad_hoc",
] as const;

export enum ActivityReviewIntervalEnum {
  Daily = "daily",
  Monthly = "monthly",
  Quarterly = "quarterly",
  Weekly = "weekly",
};

export const ActivityReviewIntervalArray = [
  "daily",
  "monthly",
  "quarterly",
  "weekly",
] as const;

export enum EngagementOptionEnum {
  LoanCallOption = "loan_call_option",
  RetainerWorkingCapital = "retainer_working_capital",
};

export const EngagementOptionArray = [
  "loan_call_option",
  "retainer_working_capital",
] as const;

export enum ExchangeListingFeeDenominatorEnum {
  Tokens = "tokens",
  Stablecoins = "stablecoins",
};

export const ExchangeListingFeeDenominatorArray = [
  "tokens",
  "stablecoins",
] as const;

export enum ExchangeListingFeeMethodologyEnum {
  Percentage = "percentage",
  Usd = "usd",
};

export const ExchangeListingFeeMethodologyArray = [
  "percentage",
  "usd",
] as const;

export enum ExchangeListingFeeTypeEnum {
  ListingAndIntegrationFee = "listing_and_integration_fee",
  MarketingBudget = "marketing_budget",
  SecurityDeposit = "security_deposit",
  MarketMakerDeposit = "market_maker_deposit",
};

export const ExchangeListingFeeTypeArray = [
  "listing_and_integration_fee",
  "marketing_budget",
  "security_deposit",
  "market_maker_deposit",
] as const;

export enum ExchangeQuestionnaireCategoriesEnum {
  BusinessModel = "business_model",
  InvestorsAndPartners = "investors_and_partners",
  General = "general",
  Tokenomics = "tokenomics",
  ProductAndRoadmap = "product_and_roadmap",
  Kpis = "kpis",
  Team = "team",
  Competition = "competition",
  AdministrativeCompliance = "administrative_compliance",
  ProductRoadmap = "product_roadmap",
  Finance = "finance",
  Community = "community",
};

export const ExchangeQuestionnaireCategoriesArray = [
  "business_model",
  "investors_and_partners",
  "general",
  "tokenomics",
  "product_and_roadmap",
  "kpis",
  "team",
  "competition",
  "administrative_compliance",
  "product_roadmap",
  "finance",
  "community",
] as const;

export enum ExchangeQuestionnaireDocDescEnum {
  FirstRoundDueDiligence = "first_round_due_diligence",
  SecondRoundDueDiligence = "second_round_due_diligence",
  RegulatoryChecklist = "regulatory_checklist",
  PreListingChecklist = "pre_listing_checklist",
  SupplementaryDueDiligence = "supplementary_due_diligence",
};

export const ExchangeQuestionnaireDocDescArray = [
  "first_round_due_diligence",
  "second_round_due_diligence",
  "regulatory_checklist",
  "pre_listing_checklist",
  "supplementary_due_diligence",
] as const;

export enum ExchangeQuestionnaireTypeEnum {
  TextInput = "text_input",
  MultipleChoice = "multiple_choice",
  UploadFile = "upload_file",
  Link = "link",
  CompleteTable = "complete_table",
  CheckBox = "check_box",
  DatePicker = "date_picker",
};

export const ExchangeQuestionnaireTypeArray = [
  "text_input",
  "multiple_choice",
  "upload_file",
  "link",
  "complete_table",
  "check_box",
  "date_picker",
] as const;

export enum MarketMakerEngagementsStatusEnum {
  New = "new",
  Active = "active",
  Inactive = "inactive",
};

export const MarketMakerEngagementsStatusArray = [
  "new",
  "active",
  "inactive",
] as const;

export enum MarketMakerEngagementsTypeEnum {
  Pmm = "PMM",
  Dmm = "DMM",
};

export const MarketMakerEngagementsTypeArray = [
  "PMM",
  "DMM",
] as const;

export enum OrganizationMemberRoleEnum {
  Owner = "owner",
  Viewer = "viewer",
};

export const OrganizationMemberRoleArray = [
  "owner",
  "viewer",
] as const;

export enum ProjectActivityStatusEnum {
  NotStarted = "not_started",
  InProgress = "in_progress",
  OnHold = "on_hold",
  Stuck = "stuck",
  Completed = "completed",
};

export const ProjectActivityStatusArray = [
  "not_started",
  "in_progress",
  "on_hold",
  "stuck",
  "completed",
] as const;

export enum ProjectStatusEnum {
  Onboarding = "onboarding",
  Active = "active",
  Deleted = "deleted",
};

export const ProjectStatusArray = [
  "onboarding",
  "active",
  "deleted",
] as const;

export enum ProjectTgeStageEnum {
  PreTge = "pre_tge",
  PostTge = "post_tge",
};

export const ProjectTgeStageArray = [
  "pre_tge",
  "post_tge",
] as const;

export enum ToolTypeEnum {
  Internal = "internal",
  External = "external",
  ThirdParty = "thirdParty",
};

export const ToolTypeArray = [
  "internal",
  "external",
  "thirdParty",
] as const;

export enum BasicCalculationDemandDenominationEnum {
  Usd = "usd",
  Tokens = "tokens",
};

export const BasicCalculationDemandDenominationArray = [
  "usd",
  "tokens",
] as const;

export enum ChanceOfVoteEnum {
  Low = "low",
  Medium = "medium",
  High = "high",
};

export const ChanceOfVoteArray = [
  "low",
  "medium",
  "high",
] as const;

export enum DemandDriverCalculationTemplateEnum {
  Basic = "basic",
  TokenBuyBacks = "token-buy-backs",
  Governance = "governance",
  Staking = "staking",
  Locking = "locking",
};

export const DemandDriverCalculationTemplateArray = [
  "basic",
  "token-buy-backs",
  "governance",
  "staking",
  "locking",
] as const;

export enum DemandDriverCalculationTypeEnum {
  Basic = "basic",
  Advanced = "advanced",
  Custom = "custom",
};

export const DemandDriverCalculationTypeArray = [
  "basic",
  "advanced",
  "custom",
] as const;

export enum DemandDriverStatusEnum {
  Active = "active",
  Inactive = "inactive",
};

export const DemandDriverStatusArray = [
  "active",
  "inactive",
] as const;

export enum DemandDriverTypeEnum {
  Utility = "utility",
  Mechanism = "mechanism",
};

export const DemandDriverTypeArray = [
  "utility",
  "mechanism",
] as const;

export enum DropOffEnum {
  Low = "low",
  Medium = "medium",
  High = "high",
};

export const DropOffArray = [
  "low",
  "medium",
  "high",
] as const;

export enum GroupCategoryEnum {
  CoreContributors = "Core Contributors",
  PrivateSale = "Private Sale",
  PublicSale = "Public Sale",
  NetworkIncentives = "Network Incentives",
  EcosystemIncentives = "Ecosystem Incentives",
  LiquidityProvision = "Liquidity Provision",
  Airdrops = "Airdrops",
  Marketing = "Marketing",
  Treasury = "Treasury",
  Miscellaneous = "Miscellaneous",
};

export const GroupCategoryArray = [
  "Core Contributors",
  "Private Sale",
  "Public Sale",
  "Network Incentives",
  "Ecosystem Incentives",
  "Liquidity Provision",
  "Airdrops",
  "Marketing",
  "Treasury",
  "Miscellaneous",
] as const;

export enum GroupTypeEnum {
  Internal = "internal",
  External = "external",
};

export const GroupTypeArray = [
  "internal",
  "external",
] as const;

export enum GroupUnlockFrequencyEnum {
  Daily = "daily",
  Monthly = "monthly",
};

export const GroupUnlockFrequencyArray = [
  "daily",
  "monthly",
] as const;

export enum GroupUnlockTypeEnum {
  EpochBased = "epoch-based",
  LockAndVest = "lock-and-vest",
  FullInitialUnlock = "full-initial-unlock",
};

export const GroupUnlockTypeArray = [
  "epoch-based",
  "lock-and-vest",
  "full-initial-unlock",
] as const;

export enum RevenueCalculationTypeEnum {
  BottomUp = "bottom-up",
  TopDown = "top-down",
  Comparable = "comparable",
};

export const RevenueCalculationTypeArray = [
  "bottom-up",
  "top-down",
  "comparable",
] as const;
