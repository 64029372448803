import { z } from 'zod'
import { initContract } from '@ts-rest/core'
import {
  ExchangesResult,
  InvestorsResult,
  MarketMakerRFQBody,
  MarketMakersResult,
  RFQDetailsResult,
} from '../types'
import { ProjectPathParam } from '../types/common'

const c = initContract()

export const marketMakerRFQContract = c.router({
  getRFQDetails: {
    method: 'GET',
    path: `market-maker-rfq/:projectId/details`,
    responses: {
      200: RFQDetailsResult,
    },
    pathParams: ProjectPathParam,
    summary: 'Get market maker rfq details.',
  },
  getInvestors: {
    method: 'GET',
    path: `market-maker-rfq/investors`,
    responses: {
      200: InvestorsResult,
    },
    summary: 'Get investors.',
  },
  getExchanges: {
    method: 'GET',
    path: `market-maker-rfq/exchanges`,
    responses: {
      200: ExchangesResult,
    },
    summary: 'Get exchanges.',
  },
  getMarketMakers: {
    method: 'GET',
    path: `market-maker-rfq/market-makers`,
    responses: {
      200: MarketMakersResult,
    },
    summary: 'Get a list of all market makers.',
  },
  saveRFQ: {
    method: 'POST',
    path: `market-maker-rfq/:projectId`,
    responses: {
      204: z.undefined().optional(),
    },
    body: MarketMakerRFQBody,
    pathParams: ProjectPathParam,
    summary: 'Save RFQ.',
  },
})

export type MarketMakerRFQContractType = typeof marketMakerRFQContract
