import { acceptHMRUpdate, defineStore } from 'pinia'
import ButtonGroup from '#account/components/onboarding/v2/OnboardingV2ButtonGroup.vue'

import Preferences from '#account/components/onboarding/v2/OnboardingV2Preferences.vue'
import TeammateDetails from '#account/components/onboarding/v2/OnboardingV2TeammateDetails.vue'
import Teammates from '#account/components/onboarding/v2/OnboardingV2Teammates.vue'
import Token from '#account/components/onboarding/v2/OnboardingV2Token.vue'
import UserDetails from '#account/components/onboarding/v2/OnboardingV2UserDetails.vue'
import Processing from '#core/components/form/UiProcessing.vue'

const MEMBERSHIP_MAX_ORGANIZATION_MEMBERS_LIMIT = 20 // todo: read from env

interface OnboardingStep {
  id: number
  slug: string
  apiName?: string
  heading: string | ComputedRef<string>
  subheading?: string
  component: Component
  bind?: any
  data: Ref<any>
  complete: boolean
  onSave?: () => void
}

type UserType = 'project-owner' | 'researcher'
export type TokenFindState = 'initial' | 'token-not-found' | 'token-selected' | 'token-not-listed'

export interface Preference {
  label: string
  icon: string
  slug: string
  description: string
}

const listedTokenPreferences: Array<Preference> = [
  {
    label: 'Analysis & Insights',
    icon: 'analysis-insights.svg',
    slug: 'analysis_and_insights',
    description: `
      <p>Track market dynamics, token metrics, and trading KPIs to maintain a competitive edge.</p>

      <ul>
        <li>Monitor your Market Maker</li>
        <li>Token Unlocks & Sell Pressure</li>
        <li>Volume, Depth, Spreads, & Price</li>
      </ul>
    `,
  },
  {
    label: 'Strategic Partnerships',
    icon: 'strategic-partnerships.svg',
    slug: 'strategic_partnerships',
    description: `
      <p>Expand your network with key industry players to bolster listing success and amplify your market presence.</p>

      <ul>
        <li>Engage Market Makers</li>
        <li>List on Exchanges</li>
        <li>Marketing & KOL Support</li>
      </ul>
    `,
  },
  {
    label: 'Performance Optimization',
    icon: 'performance-optimization.svg',
    slug: 'performance_optimization',
    description: `
      <p>Fine-tune your tokenomics, enhance smart contracts, and maximize token utility for sustained growth.</p>

      <ul>
        <li>Tokenomics Revision</li>
        <li>Self-service Market Making</li>
        <li>Smart Contract & Protocol Development</li>
        <li>Airdrops & Token Streaming</li>
      </ul>
    `,
  },
  {
    label: 'Finance & Fundraising',
    icon: 'finance-fundraising.svg',
    slug: 'finance_and_fundraising',
    description: `
      <p>Analyze financial health and secure capital to fuel your project’s expansion and innovation.</p>

      <ul>
        <li>Cashflow Analysis</li>
        <li>Growth Capital</li>
      </ul>
    `,
  },
  {
    label: 'Legal & Compliance',
    icon: 'legal-compliance.svg',
    slug: 'legal_compliance',
    description: `
      <p>Navigate regulatory landscapes with expert legal guidance tailored to your project’s needs.</p>

      <ul>
        <li>External General Counsel</li>
        <li>Legal Opinion on Token</li>
      </ul>
    `,
  },
]

const projectOwnerPreferences: Array<Preference> = [
  {
    label: 'Tokenomics & Protocol',
    icon: 'tokenomics-protocol.svg',
    slug: 'tokenomics_and_protocol',
    description: `
      <p>Design & develop your protocol, mint tokens, and automate distribution of your cap table.</p>

      <ul>
        <li>Design Tokenomics</li>
        <li>Smart Contract Development</li>
        <li>Token Creation & Mint</li>
        <li>Airdrops & Token Streaming</li>
        <li>Token Custody</li>
      </ul>
    `,
  },
  {
    label: 'Go-to-Market Partnerships',
    icon: 'go-to-market-partnerships.svg',
    slug: 'go_to_market_partnerships',
    description: `
      <p>Align with market makers, exchanges, and KOLs to promote a strategic launch.</p>

      <ul>
        <li>Engage Market Makers</li>
        <li>List on Exchanges</li>
        <li>Marketing & KOL Support</li>
      </ul>
    `,
  },
  {
    label: 'Legal & Compliance',
    icon: 'legal-compliance.svg',
    slug: 'legal_compliance',
    description: `
      <p>Structure your foundation and secure a legal opinion regarding the regulatory status of your token.</p>

      <ul>
        <li>Entity formation & incorporation</li>
        <li>Engage external General Counsel</li>
        <li>Get a Legal Opinion on your Token</li>
      </ul>
    `,
  },
  {
    label: 'Finance & Fundraising',
    icon: 'finance-fundraising.svg',
    slug: 'finance_and_fundraising',
    description: `
      <p>Construct your budget & cashflow model. Prepare for token sales (private or public) or equity transaction.</p>

      <ul>
        <li>Conduct Cashflow Analysis</li>
        <li>Growth Capital</li>
        <li>Go-to-Market Budgeting</li>
        <li>Public Sale, Launchpad</li>
      </ul>
    `,
  },
]

const researcherPreferences: Array<Preference> = [
  {
    label: 'Tokenomics Research',
    slug: 'tokenomics_research',
    icon: 'tokenomics-research.svg',
    description: `Conduct research on tokenomics & protocol value flows. Interact with Forgd frameworks for designing emissions, demand drivers, and value accrual mechanisms.`,
  },
  {
    label: 'Market Making Research',
    slug: 'market_making_research',
    icon: 'market-making-research.svg',
    description: `Conduct research on market makers & liquidity provision. Learn best practices for engaging service providers with symbiotic relationships.`,
  },
  {
    label: 'Exchange Listing Research',
    slug: 'exchange_listing_research',
    icon: 'exchange-listing-research.svg',
    description: `Conduct research on exchanges. Assess performance of past listings, trading KPIs, fee structures, and more.`,
  },
]

export const useOnboardingV2 = defineStore('onboarding', () => {
  const auth = useAuth()
  const client = useClient()
  const route = useRoute()
  const router = useRouter()

  /**
   * Teammate onboarding
   */
  const isTeammate = ref(route.query?.as === 'teammate')
  const organizationId = ref(route.query?.organizationId)
  const projectToJoin = computed(
    () => auth.organizations?.find(org => org.id === organizationId.value)?.projects[0] || null,
  )
  const showBackButton = computed(() => currentStep.value.id > 1 && !isTeammate.value)

  const userType = ref<UserType | null>(null)
  const preferences = computed(() => {
    if (userType.value === 'researcher') {
      return researcherPreferences
    }
    else if (tokenIsListed.value) {
      return listedTokenPreferences
    }
    else {
      return projectOwnerPreferences
    }
  })

  const hasToken = ref<boolean | null>(auth.project?.hasToken || null)
  const tokenIsListed = ref<boolean | null>(auth.project?.tokenListed || null)
  const projectId = ref<string | null>(auth.project?.id || null)
  const onboardingProject = ref()

  const tokenFindState = ref<TokenFindState>('initial')

  const steps: Array<OnboardingStep> = [
    {
      id: 1,
      slug: 'user-type',
      apiName: 'projectType',
      heading:
        'Are you part of a blockchain project that has a token or will it issue one in the future?',
      component: shallowRef(ButtonGroup),
      bind: {
        options: [
          { id: 1, value: 'project-owner', label: 'Yes' },
          { id: 2, value: 'researcher', label: 'No. I want to use Forgd to conduct research.' },
        ],
      },
      data: ref(),
      onSave: async () => {
        const userType = steps[0].data.value.value
        const isResearch = userType === 'researcher'
        hasToken.value = !isResearch

        /**
         * If there isn't a project associated with this user's organization, create a new one
         */
        if (!projectId.value) {
          const res = await client.onboarding.initProject({
            body: {
              isResearch,
              organizationId: auth.organization!.id,
            },
          })
          if (res.status === 200) {
            projectId.value = res.body.id
            onboardingProject.value = res.body
          }
        }

        /**
         * Otherwise, just save the user type (isResearch)
         */
        else {
          await client.onboarding.updateProjectType({
            body: {
              projectId: projectId.value,
              isResearch,
            },
          })
        }

        // forcing auth to update
        await auth.refresh({ from: 'useOnboardingV2:step1:onSave' })

        currentStep.value = isResearch ? steps[3] : steps[1]
      },
      complete: false,
    },
    {
      id: 2,
      slug: 'token-status',
      apiName: 'tokenListed',
      heading:
        'Is your token listed on centralized or decentralized exchanges?',
      component: shallowRef(ButtonGroup),
      bind: {
        options: [
          { id: 1, value: 'listed', label: 'Yes. The token is listed' },
          { id: 2, value: 'pre-tge', label: 'No. The token is not listed yet' },
        ],
      },
      data: ref(),
      onSave: async () => {
        await nextTick() // let the watcher fire
        await client.onboarding.updateTokenListed({
          params: {
            projectId: projectId.value!,
          },
          body: {
            tokenListed: tokenIsListed.value!,
          },
        })
        currentStep.value = steps.find(step => step.apiName === 'projectDetails')!
      },
      complete: false,
    },
    {
      id: 3,
      slug: 'find-token',
      apiName: 'projectDetails',
      heading: `Let's find your token.`,
      component: shallowRef(Token),
      data: ref(),
      complete: false,
    },
    {
      id: 4,
      slug: 'preferences',
      apiName: 'areasOfInterest',
      heading: 'How do you plan to use Forgd?',
      subheading: 'Select at least one option. You can always change this later.',
      component: shallowRef(Preferences),
      data: ref([]),
      complete: false,
    },
    {
      id: 5,
      slug: 'user-details',
      apiName: 'ownerDetails',
      heading: `Great! We are almost finished. Let's wrap up a few additional details.`,
      component: shallowRef(UserDetails),
      data: ref(),
      complete: false,
    },
    {
      id: 6,
      slug: 'invite-teammates',
      apiName: 'memberInvitations',
      heading: `Invite teammates to collaborate`,
      subheading: `Add up to ${MEMBERSHIP_MAX_ORGANIZATION_MEMBERS_LIMIT}`,
      component: shallowRef(Teammates),
      data: ref(),
      complete: false,
      onSave: async () => {
        currentStep.value = steps[7]
      },
    },
    {
      id: 7,
      slug: 'teammate-details',
      heading: computed(() => `You have been invited to joining ${projectToJoin.value?.name} as a teammate`),
      component: shallowRef(TeammateDetails),
      data: ref(),
      complete: false,
      onSave: async () => {
        navigateTo(auth.dashboardPath)
      },
    },
    {
      id: 8,
      slug: 'processing',
      heading: '',
      component: shallowRef(Processing),
      bind: {
        title: 'We are processing your account details',
        subtitle: 'This might take a few seconds',
      },
      data: ref(),
      complete: false,
    },
  ]

  const currentStep: Ref<OnboardingStep> = ref(steps[0])

  /**
   * I don't love this but it works
   */
  watch(steps[0].data, (newVal) => {
    if (newVal.value === 'project-owner') {
      userType.value = 'project-owner'
    }
    else {
      userType.value = 'researcher'
    }
  }, {
    deep: true,
  })

  function setTokenIsListedState(value: boolean) {
    if (value) {
      tokenIsListed.value = true
      tokenFindState.value = 'initial'
      steps[2].heading = `Let's find your token.`
    }
    else {
      tokenIsListed.value = false
      tokenFindState.value = 'token-not-listed'
      steps[2].heading = `Tell us a bit more about your project & token.`
    }
  }

  watch(steps[1].data, (newVal) => {
    if (newVal.value === 'listed') {
      setTokenIsListedState(true)
    }
    else {
      setTokenIsListedState(false)
    }
  }, {
    deep: true,
  })

  /**
   * Update the URL hash when the current step changes without overwriting the query parameters
   */
  watch(currentStep, (newVal) => {
    if (newVal) {
      const query = isTeammate.value
        ? {
            organizationId: route.query.organizationId,
            as: 'teammate',
          }
        : {}
      router.push({
        hash: `#${newVal.slug}`,
        query,
      })
    }
  })

  async function next() {
    const index = steps.findIndex(step => step.id === currentStep.value.id)
    if (steps[index].onSave) {
      await steps[index].onSave()
    }
    else {
      await setCurrentStep()
    }
  }

  function previous() {
    const index = steps.findIndex(step => step.id === currentStep.value.id)
    const previousStep = steps[index - 1]

    // don't send researchers to the token details step via the back button
    // this should be part of the OnboardingStep interface
    if (previousStep.apiName === 'projectDetails' && userType.value !== 'project-owner') {
      currentStep.value = steps[0]
    }
    else {
      currentStep.value = steps[index - 1]
    }
  }

  const progress = computed(() => {
    const index = steps.findIndex(step => step.id === currentStep.value.id) + 1
    return index / steps.length * 100
  })

  async function setOnboardingAsTeammate({ onboardToOrganizationId }: { onboardToOrganizationId: string }) {
    organizationId.value = onboardToOrganizationId
    isTeammate.value = true
    currentStep.value = steps[6]
  }

  async function setCurrentStep() {
    projectId.value = auth.project?.id || null

    if (projectId.value) {
      const res = await client.onboarding.getProgress({
        params: {
          projectId: projectId.value,
        },
      })
      if (res.status === 200 && !isTeammate.value) {
        const progress = res.body

        /**
         * Restore internal state from auth.project
         */
        if (auth.project?.isResearch) {
          userType.value = 'researcher'
        }
        else {
          userType.value = 'project-owner'
        }
        if (tokenFindState.value === 'initial' && progress.tokenListed && auth.project?.tokenListed === false) {
          setTokenIsListedState(false)
        }

        /**
         * Set the current step
         */
        if (!progress.projectType) {
          currentStep.value = steps.find(step => step.apiName === 'projectType')!
        }
        else if (!progress.tokenListed && userType.value === 'project-owner') {
          currentStep.value = steps.find(step => step.apiName === 'tokenListed')!
        }
        else if (!progress.projectDetails && userType.value === 'project-owner') {
          currentStep.value = steps.find(step => step.apiName === 'projectDetails')!
        }
        else if (!progress.areasOfInterest) {
          currentStep.value = steps.find(step => step.apiName === 'areasOfInterest')!
        }
        else if (!progress.ownerDetails) {
          currentStep.value = steps.find(step => step.apiName === 'ownerDetails')!
        }
        else if (!progress.memberInvitations) {
          currentStep.value = steps.find(step => step.apiName === 'memberInvitations')!
        }
        else {
          await client.onboarding.complete({
            body: {
              projectId: projectId.value as string,
              members: [],
            },
          })
          await auth.refresh({ from: 'useOnboardingV2:setCurrentStep' })
          navigateTo(auth.dashboardPath)
        }
      }
    }
  }

  return {
    steps,
    currentStep,
    setCurrentStep,
    setOnboardingAsTeammate,
    next,
    showBackButton,
    previous,
    preferences,
    userType,
    hasToken,
    tokenIsListed,
    progress,
    projectId,
    tokenFindState,
    projectToJoin,
    isTeammate,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOnboardingV2, import.meta.hot))
}
